// FacebookPixel.js

import React, { useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';

const Facebook = ({title}) => {
    const advancedMatching = { em: 'some@email.com' }; 
const options = {
  autoConfig: true, 
  debug: false,
};
ReactPixel.init('282551210445191', advancedMatching, options);

  useEffect(() => {
    ReactPixel.trackSingle('282551210445191', title, {});
  }, []);

  return null;
};

export default Facebook;
