import React, { useState, useEffect, useContext } from 'react';
import db from "../Firebase"; // Adjust the path to your firebase.js file
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import './details.css'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Cookies from 'js-cookie';
import Navbar from './Navbar';

const CustomerDetails = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false); // New loading state
    const [customerDetails, setCustomerDetails] = useState({
        customerName: '',
        flat_villa_no: '',
        floor: '',
        customerPhone: '',
        customerAddress: '',
        customerType: false,
        city: '',
        state: '',
        pincode: '',
        hubName: '',
        location: '',
        deliveryExecutiveId: ''
    });
    const [hubs, setHubs] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        const cookieValue = Cookies.get('user');
        if (cookieValue) {
            const parsedValue = JSON.parse(cookieValue);
            const phoneNumber = parsedValue.phone;
            const fetchCustomerData = async () => {
                try {
                    const customersRef = db.collection('customers_data');
                    const querySnapshot = await customersRef.where('customer_phone', '==', phoneNumber).get();
                    if (!querySnapshot.empty) {
                        navigate("/");
                    }
                } catch (error) {
                    console.error('Error fetching customer data:', error);
                }
            };
            fetchCustomerData();
        }
    }, []);

    useEffect(() => {
        const cookieValue = Cookies.get('user');
        const parsedValue = JSON.parse(cookieValue);
        const phoneNumber = parsedValue.phone;
        setPhone(phoneNumber);
    }, []);

    useEffect(() => {
        const fetchHubs = async () => {
            try {
                const hubCollection = db.collection('hubs_data');
                const hubSnapshot = await hubCollection.get();
                const hubList = hubSnapshot.docs.map(doc => doc.data().hub_name);
                setHubs(hubList);
            } catch (error) {
                console.error('Error getting documents:', error);
            }
        };
        fetchHubs();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (name === 'hubName') {
            fetchLocations(value);
        }
        if (name === 'location') {
            fetchDeliveryExecutiveId(value);
        }
    };

    const fetchLocations = async (hubName) => {
        try {
            const locationCollection = db.collection('hubs_locations_data');
            const locationSnapshot = await locationCollection.where('hub_name', '==', hubName).get();
            const locationList = locationSnapshot.docs.map(doc => doc.data().location);
            setLocations(locationList);
        } catch (error) {
            console.error('Error getting locations:', error);
        }
    };

    const fetchDeliveryExecutiveId = async (location) => {
        try {
            const locationCollection = db.collection('hubs_locations_data');
            const locationSnapshot = await locationCollection.where('location', '==', location).get();
            const deliveryExecutiveId = locationSnapshot.docs[0]?.data().delivery_exe_id || '';
            setCustomerDetails(prevDetails => ({
                ...prevDetails,
                deliveryExecutiveId
            }));
        } catch (error) {
            console.error('Error getting delivery executive id:', error);
        }
    };

    function generateCustomerId() {
        const now = new Date();
        const timestamp = now.getTime();
        const random4Digits = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
        const customerId = (timestamp % 10000).toString().padStart(4, '0') + random4Digits;
        return customerId;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
    
        try {
            // Check if the customer is already registered based on their phone number
            const customerQuery = await db.collection('customers_data')
                .where('customer_phone', '==', phone)
                .get();
    
            if (!customerQuery.empty) {
                // If customer is already registered, show an alert
                alert('User is already registered');
                setLoading(false); // Stop loading
                return;
            }
    
            // Generate customer ID and proceed with adding the new customer if not already registered
            const customerId = generateCustomerId();
            await db.collection("customers_data").add({
                'customer_id': customerId,
                'customer_image': "",
                'customer_type': false,
                'customer_name': customerDetails.customerName,
                'customer_category': "Lead",
                'hub_name': customerDetails.hubName,
                'customer_phone': phone,
                'alt_phone': "",
                'customer_email': "",
                'dob': new Date(),
                'anniversary_date': new Date(),
                'customer_address': customerDetails.customerAddress,
                'flat_villa_no': customerDetails.flat_villa_no,
                'floor': customerDetails.floor,
                'landmark': "",
                'location': customerDetails.location,
                'pincode': customerDetails.pincode,
                'gender': "",
                'platform': "website",
                'source': "1",
                'delivery_exe_id': customerDetails.deliveryExecutiveId,
                'wallet_balance': 0,
                'credit_limit': 110,
                'status': "1",
                'updated_date': new Date(),
                'created_date': new Date(),
                'referral_code': "",
                'latitude': "",
                'longitude': "",
                'registered_time': "",
                'registered_date': new Date(),
            });
    
            // Navigate to the next page (e.g., cart)
            navigate('/cart');
        } catch (error) {
            console.error('Error adding document: ', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };
    
    return (
        <>
        <Navbar/>
            <div className="card cards card-2 text-center" style={{ width: '30rem' }}>
                <div className="custom-header">
                    <h1>Customer Details</h1>
                </div>
                <div className="card-body" style={{ marginTop: '20px' }}>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-1">
                            <Form.Group as={Col} controlId="formGridName">
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control name="customerName" type="text" placeholder="Enter name" value={customerDetails.customerName} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                        <br />
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Hub Name</Form.Label>
                                <Form.Select name="hubName" value={customerDetails.hubName} onChange={handleChange}>
                                    <option value="">Select Hub</option>
                                    {hubs.map(hub => (
                                        <option key={hub} value={hub}>{hub}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Location</Form.Label>
                                <Form.Select name="location" value={customerDetails.location} onChange={handleChange}>
                                    <option value="">Select Location</option>
                                    {locations.map(location => (
                                        <option key={location} value={location}>{location}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <br />
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridflatvillano">
                                <Form.Label>Flat/Villa No.</Form.Label>
                                <Form.Control name="flat_villa_no" placeholder="Flat/Villa No." value={customerDetails.flat_villa_no} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridfloor">
                                <Form.Label>Floor</Form.Label>
                                <Form.Control name="floor" placeholder="Floor" value={customerDetails.floor} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                        <br />
                        <Form.Group className="mb-3" controlId="formGridAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Address" name="customerAddress" value={customerDetails.customerAddress} onChange={handleChange} />
                        </Form.Group>
                        <br />
                        <button className="subscribe-button" type="submit" disabled={loading}>
                            {loading ? 'Processing...' : 'Confirm Details'}
                        </button>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default CustomerDetails;
